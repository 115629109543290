<template>
  <div class="dashboard__container--body">
    <Loader v-if="!supportRequests || supportRequests.length == 0" />
    <vue-good-table
        :columns="columns"
        :rows="sortedClosedTickets"
         styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }"
      >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'created'">
          <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
        </span>
        <span v-else-if="props.column.field == 'status'">
          <v-select
            label="title" 
            :options="statuses"
            v-model="props.row.status"
            @input="updateStatus(props.row)"
            :clearable="false"
            >
          </v-select>
        </span>
        <span v-else-if="props.column.field == 'link'">
          <router-link :to="`/users/` + props.row.id" target="_blank">
            <i class="fas fa-external-link ml-3 mr-3"></i>
          </router-link>
        </span>
        <span v-else-if="props.column.field == 'name'">
          <span v-if="props.row.firstname">{{props.row.firstname}}</span><span v-if="props.row.lastname"> {{props.row.lastname}}</span>
        </span>
       
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>


<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'closedTickets',
  data: () => ({
    statuses: ['New', 'In Progress', 'Closed'],
    columns: [
      {
        label: 'Ticket Status',
        field: 'status',
        width: '180px',
      },
      {
        label: 'Created',
        field: 'created',
        width: '128px',
      },
      {
        label: 'Name',
        field: 'name',
        width: '180px',
        sortable: false,
      },
      {
        label: 'Link',
        field: 'link',
        width: '60px',
        sortable: false,
      },
      {
        label: 'Email',
        field: 'email',
        width: '144px',
      },
      {
        label: 'Phone',
        field: 'phone',
        width: '128px',
        sortable: false,
      },
      {
        label: 'Details',
        field: 'details',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'supportRequests']),
    closedTickets() {
      return this.supportRequests.filter(item => {
        return (item.status == 'Closed')
      })
    },
    sortedClosedTickets() {
      return Object.values(this.closedTickets).sort((a, b) => b.created.seconds - a.created.seconds)
    },
  },
  components: {
    Loader,
  },
  methods: {
    updateStatus(row) {
      fb.supportRequestCollection.doc(row.id).update(row)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
  },
}
</script>